import React from 'react'

export default function LeftCard(props) {
    return (
        <div className=" flex service-card service-card-left "  >
            <img alt="service-img" className="service-card-img"  src={props.img}/>
            <div className="service-card-details">
                <h1>{props.name}</h1>
                <p>{props.description}</p>
            </div>
        </div>
    )
}
