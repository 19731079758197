import React from 'react'

export default function RightCard(props) {
    return (
        <div className="service-card service-card-right flex" >
            <div className="service-card-details">
                <h1>{props.name}</h1>
                <p>{props.description}</p>
            </div>
            <img alt="service" className="service-card-img" src={props.img}/>
        </div>
    )
}
