import React from 'react'
import Helmet from "react-helmet"
import { withPrefix } from 'gatsby'
import "../styles/global.css"
import Navbar from './Navbar'
import Footer from './Footer'
import SEO from './Seo'

export default function Layout({children}) {
    return (
        <div className="layout"  >
             <SEO/>
                                    
               
         
             <Navbar logo="./logo.png" color="transparent" />


             <div>
                {children}
            </div>
            <Footer/>

        </div>
    )
}
