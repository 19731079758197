import React from 'react'
export default function Header() {
    return (
        <div>
             <img id="home"  alt="Pharmacie La Roche" src="./background-img.jpg" className="header"/>
            <div className="header-title">
                      <h1>Votre Santé... <br/>Notre Passion</h1>
            </div>
        </div>
    )
}
