import React from 'react'
//import FinalSlider from './FinalSlider';
import ProductCard from './ProductCard';

export default function Products() {
     // The current width of the viewport
   /*  let width ; 
     if (typeof window !== "undefined") {
          width = window.innerWidth;
 
       }
   
     // The width below which the mobile view should be rendered
     const breakpoint = 768;*/
    return (
        <div  id="products" className="products" > 
             <div className="section-title">
                            <h1>Nos Produits</h1>
                            <hr/>
                    </div>
                <img src="./products-deco.png" alt="shape" className="product-shape"/>
              
            
              
                <div className="grid-container"> 
                    <ProductCard id="1" title="Médicaments" img="./product-medic.png" />  
                    <ProductCard id="2" title="Espace beauté & Dermo-cosmétique" img="./product-beauty.png" />  
                    <ProductCard id="3" title="Orthopédie" img="./product-ortho.png" />  
                    <ProductCard id="4" title="Matériel Médical" img="./product-material.png" />  
                    <ProductCard id="5" title="Nutrition" img="./product-nutrition.png" />  
                    <ProductCard id="6" title="Bébé & Maman" img="./product-bebe.png" />  
                    <ProductCard id="7" title="Nature" img="./product-nature.png" />  
                    <ProductCard id="8" title="Produits Vétérinaires" img="./product-viterinaire.png" />  
                    <ProductCard id="9" title="Hygiéne & Santé" img="./product-sante.png" />  
                 </div> 
        </div>
        
    )
}
