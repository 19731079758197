import React, { Component } from 'react'
import { Link } from 'gatsby'

export default class ProductCard extends Component {
    render() {
        return (
            <div className="grid-item product-box">
              <Link to={"/produits/"+this.props.id}  activeStyle={{width:"100%",height:"100%", }}  >

              <img width="100px" height="100px" src={this.props.img} alt="product-img" className="product-icon" />  
              <h4 className="product-title"  >{this.props.title}</h4> 
              </Link>
 
            </div>
          
        ) 
    }
}

