import React from 'react'

export default function AboutUs() {
    return (
        <div id="aboutus" className="container-aboutus">
            <img src="/about-deco.png" alt="deco" className="about-deco1"/>
            <img src="/about-deco.png" alt="deco" className="about-deco2"/>

            <div className="aboutus-content">
               <div className="section-title">
                     <h1>Qui sommes-nous ?  </h1>
                     <hr/>
               </div>
               <h6 > 
                    Pharmacie la Roche est plus qu'une pharmacie, c'est un espace qui vous offre les meilleurs services et la commodité de choisir parmi les meilleurs de nos produits, en mettant à votre disposition une équipe qualifiée, chaleureuse et dynamique pour vous écouter et vous fournir les meilleurs conseils ainsi pour une analyse précise des ordonnances avant dispensation des médicaments. 
                    <br/><br/>Le large espace dédié aux produits parapharmaceutiques vous permet de basculer et de choisir parmi les meilleurs produits de qualité aux meilleurs prix. 
                    <br/><br/>Notre équipe est toujours là pour répondre au mieux à vos besoins et à vos attentes.  
                </h6>

            </div>
        </div>
    )
}
