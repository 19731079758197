import React from 'react'
import {SiGooglemaps, SiWaze} from 'react-icons/si';
import {AiOutlineMessage} from 'react-icons/ai';
import {MdOutlineWifiCalling3,MdPlace} from 'react-icons/md';
import {FaInstagram,FaFacebookF,FaWhatsapp,FaWaze} from 'react-icons/fa';
import { navigate } from 'gatsby-link'

function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

export default function Contact() {
    const [state, setState] = React.useState({nom:"",email:"",num:"",message:""})
    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value }) 
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            ...state,
          }),
        })
          .then(() => {navigate(form.getAttribute('action'))
          alert("Message envoyé avec succés. On y réponderai dés que possible!");
          })
          .catch((error) => alert(error))
          e.preventDefault();
          setState({nom:"", email:"",num:"", message:""}) // <= here
    }

    return (
    <section  style={{backgroundColor:"#FAFAFA"}} className="contact" id="contact">
             <div className="section-title">
                    <h1>Contact</h1>
                    <hr/>
             </div>
             
             <div className="grid-container">
                   
                   <div className="grid-item contact-box">
                       <div style={{display:"flex", flexDirection:"row"}}>
                                <AiOutlineMessage className="icon" />  
                                <h3 className="product-title"  >Envoyez-nous  <br/> un message !</h3>  
                       </div>
                       <ul className="social-medias" style={{marginTop:"20px"}}>
                            <li>
                                <a className="link" target="_blank"  href="https://www.facebook.com/pharmacielaroche" rel="noreferrer">
                                    <FaFacebookF className="social-icon social-media"/>          
                                </a> 
                            </li>
                            <li>
                                <a className="link" target="_blank"  href="https://www.instagram.com/pharmacie.laroche/" rel="noreferrer">
                                    <FaInstagram className="social-icon social-media"/>          
                                </a> 
                            </li>
                            
                            <li>
                                <a className="link" target="_blank"  href="https://api.whatsapp.com/send/?phone=213561890360&text&app_absent=0" rel="noreferrer">
                                    <FaWhatsapp className="social-icon social-media"/>          
                                </a> 
                            </li>
                        </ul>
                    </div>
                    <div className="grid-item contact-box">
                       <div style={{display:"flex", flexDirection:"row"}}>
                                <MdOutlineWifiCalling3 className="icon" />  
                                <h3 className="product-title"  >Appelez nous  <br/> 24/7 service</h3>  
                       </div>  <br/>
                       <h4>031693892</h4>  
                       <h4>0561890360</h4>  
                    </div>
                    <div className="grid-item contact-box">
                       <div style={{display:"flex", flexDirection:"row"}}>
                                <MdPlace className="icon"/>  
                                <h3 className="product-title">Rendez-nous  <br/> visite !</h3>  
                       </div>
                       <h4>Lotissement EL BEY Nº31 cité Zouaghi, AIN EL BEY, 25000 </h4>  
                       <ul className="social-medias" style={{marginTop:"20px"}}>
                            <li>
                                <a className="link" target="_blank"  href="https://www.waze.com/live-map/directions/algeria/constantine-province/constantine/pharmacie-la-roche?navigate=yes&to=place.ChIJJQZPiZ1x8RIRV0cs6z1fCjg" rel="noreferrer">
                                    <FaWaze className="social-icon social-media"/>           
                                </a> 
                            </li>
                            <li>
                                <a className="link" target="_blank"  href="https://www.google.com/maps/place/Pharmacie+La+Roche/@36.3103074,6.6269261,15z/data=!4m5!3m4!1s0x0:0x380a5f3deb2c4757!8m2!3d36.3103074!4d6.6269261" rel="noreferrer">
                                    <MdPlace className="social-icon social-media"/>          
                                </a> 
                            </li>
                          
                        </ul>
                      
                    </div>
            
            </div>

            <div className="flex form-container">     
                <form name="contact" method="post" action="/#contact" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit}>
                        <h1 className="heading">Contactez-nous</h1>
                        <input type="hidden" name="form-name" value="contact" />
                        <p hidden>
                        <label>
                            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                        </label>
                        </p>
                        <div className="inputBox">
                            <input name="nom" type="text" value={state.nom} required onChange={handleChange}/>
                            <label>Nom</label>
                        </div>
                        <div className="inputBox">
                            <input name="email" type="email" value={state.email} required onChange={handleChange}/>
                            <label>Email</label>
                        </div>
                        <div className="inputBox">
                            <input name="num" type="number" value={state.num} required onChange={handleChange}/>
                            <label>N.téléphone</label>
                        </div>
                        <div className="inputBox">
                            <textarea name="message" required value={state.message} cols="30" rows="10" onChange={handleChange}></textarea>
                            <label>Message</label>
                        </div>
                        <input  className="submit-btn" type="submit" value="Contactez-nous !"/>
             
                    </form> 
                    <iframe  className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3215.082450453149!2d6.624737415356488!3d36.31030738005247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12f1719d894f0625%3A0x380a5f3deb2c4757!2sPharmacie%20La%20Roche!5e0!3m2!1sen!2sus!4v1634763901432!5m2!1sen!2sus"
                    allowfullscreen="" style={{border:0}}></iframe>
           

         </div>
                  


    
        </section>
    )
}
