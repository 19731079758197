import React from "react"
import AboutUs from "../components/AboutUs"
import Contact from "../components/Contact"
import CustomerChat from "../components/CustomerChat"
import Header from "../components/Header"
import Layout from "../components/Layout"
import Products from "../components/Products"
import Services from "../components/Services"

export default function Home() {
  return (
    <Layout>
       <Header/>
       <CustomerChat/>
       <AboutUs/>
       <Products/>
       <Services/>
       <Contact/>  
    </Layout>
  )
 

  
}
