import React from 'react'
import LeftCard from './LeftCard';
import RightCard from './RightCard';

export default function Services() {
    // The current width of the viewport
    let width ; 
    if (typeof window !== "undefined") {
         width = window.innerWidth;

      }
    // The width below which the mobile view should be rendered
    const breakpoint = 768;

    return (
        <div id="services"  >
            <div className="section-title">
                            <h1>Nos Services</h1>
                            <hr/>
            </div>

        <div style={{position:"relative", display:"flex", flexDirection:"column", backgroundColor:"#C6E7C9", border:"none", borderWidth:"0px"}} >
            <div className="custom-shape-divider-top-1633636836">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
        </div>

           <div style={{marginTop:"6rem", zIndex:9, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
        
                <LeftCard img="./service1.png" name="Livraison à Domicile" description="Pharmacie La Roche met à votre disposition un personnel qualifié dédié à vous livrer vos produits à la demande, la livraison à domicile sera gratuite au niveau de la wilaya de Constantine." />
                
                {width < breakpoint ?
                  <LeftCard img="./service2.png" name="Commande Spéciale" description="Pharmacie La Roche s'engage à prendre en charge votre commande, et à vous délivrer le produit voulu tant que celui-ci existe sur le marché." />
                 : <RightCard img="./service2.png" name="Commande Spéciale" description="Pharmacie La Roche s'engage à prendre en charge votre commande, et à vous délivrer le produit voulu tant que celui-ci existe sur le marché." />
                }

                <LeftCard img="./service3.png" name="Cabine Orthopédie" description="Pharmacie La Roche met à votre disposition un espace Orthopédique pour essayer les différents articles à votre aise afin de vous assurer de votre choix." />
                
                {width < breakpoint ?
                <LeftCard img="./service4.png" name="Espace de Confidentialité" description="Pharmacie La Roche vous dispose d'un espace confidentiel." />
                :  <RightCard img="./service4.png" name="Espace de Confidentialité" description="Pharmacie La Roche vous dispose d'un espace confidentiel." />

                }


               
         </div>

         <div className="custom-shape-divider-bottom-1633636673">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
        </div>

        </div>
        </div>
    )
}
