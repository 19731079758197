import React from 'react'
import {FaInstagram,FaFacebookF,FaWhatsapp} from 'react-icons/fa';

export default function Footer() {
    return (
        <footer style={{backgroundColor:"#98AFBA"}}>
           
                <div style={{justifyContent:"space-around", padding:"20px", position:"relative"}}  className="reverse">
                    <div id="footer-info" style={{paddingBottom:"4rem"}}>
                        <h2>Pharmacie La Roche.</h2>

                        <h2>Droits d'auteur @2021 Tous droits reservés</h2>
                       
                    </div>
                   
                    <div id="footer-links" >
                        <ul>
                            <li><a href="#home">Accueil</a></li>
                            <li><a href="#about">Qui sommes-nous ?</a></li>
                            <li><a href="#products">Produits</a></li>
                            <li><a href="#services">Services</a></li>
                            <li><a href="#contact">Contact</a></li>

                        </ul>
                    </div>
                    <div style={{marginBottom:"10px"}} style={{alignSelf:"center"}}>
                        <ul className="footer-icons">
                            <li>
                                <a className="link" target="_blank"  href="https://www.facebook.com/pharmacielaroche" rel="noreferrer">
                                    <FaFacebookF className="social-icon"/>          
                                </a> 
                            </li>
                            <li>
                                <a className="link" target="_blank"  href="https://www.instagram.com/pharmacie.laroche/" rel="noreferrer">
                                    <FaInstagram className="social-icon"/>          
                                </a> 
                            </li>
                            
                            <li>
                                <a className="link" target="_blank"  href="https://api.whatsapp.com/send/?phone=213561890360&text&app_absent=0" rel="noreferrer">
                                    <FaWhatsapp className="social-icon"/>          
                                </a> 
                            </li>
                        </ul>
                    </div>
                </div>
        </footer>
    )
}
